body {
	/* Cursor styles */
	--cursor-stroke: #FF6464;
	/*--cursor-stroke: #c3654e;*/
	--cursor-fill: none;
	--cursor-stroke-width: 1px;
}

.cursor {
	display: none;
}

@media (any-pointer: fine) {
	.cursor {
		position: fixed !important;
		top: 0;
		left: 0;
		display: block;
		pointer-events: none;
		opacity: 0;
		z-index:99;
	}
	.cursor__inner {
		fill: var(--cursor-fill);
		stroke: var(--cursor-stroke);
		stroke-width: var(--cursor-stroke-width);
	}
}
